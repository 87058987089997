

export default {
  /* 数组转化成字符串 => 业务范围 */
  businessScopeArrToStr(arr:any,businessOptions:any) {
    if (!arr) {
      return "--";
    } else {
      if (arr.length) {
       return (businessOptions||[]).filter((f:any)=>{
          return arr.indexOf(f.value)>-1
        }).map((m:any)=>{return m.label}).join("；")
      } else {
        return "--";
      }
    }
  },
    // 获取字典（key）
    getSelectOption(key: string) {
      const dictionary: any = window.sessionStorage.getItem("dictionary");
      const allList = JSON.parse(dictionary);
      let result: any = [];
      allList.map((item:any) => {
        if (item.key === key) {
          result = item.values;
        }
      });
      return result;
    },
  // 2020-20-20 20:20:20  ---> 2020-20-20
  getTimeDate(_val:any, time = false) {
    if (!_val) {
      return _val;
    }
    if(_val.startsWith("000")){
      return "--"
    }
    if (_val.indexOf("T") !== -1) {
      return _val.split("T")[0];
    } else {
      let date = _val.split(" ")[0];
      let hms = _val.split(" ")[1]
      if (date.indexOf("/") !== -1) {
        let arr = date.split("/");
        arr[1] =
          Number(arr[1]) >= 10 || arr[1].length > 1 ? arr[1] : "0" + arr[1];
        arr[2] =
          Number(arr[2]) >= 10 || arr[2].length > 1 ? arr[2] : "0" + arr[2];
        return time ? arr.join("-") + " " + hms : arr.join("-");
      } else {
        return date;
      }
    }
  },
  /* value相关 */
  // get label by value
  getLabelByValue(value: any, list: any) {
    if(Array.isArray(list))
    for (let item of list) {
      if (value == item.value) {
        return item.label;
      }
    }
    return value;
  },
 // 身份证校验
 validateIdCard(idCard: any) {
  //15位和18位身份证号码的正则表达式
  var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

  //如果通过该验证，说明身份证格式正确，但准确性还需计算
  if (regIdCard.test(idCard)) {
    if (idCard.length == 18) {
      var idCardWi = new Array(
        7,
        9,
        10,
        5,
        8,
        4,
        2,
        1,
        6,
        3,
        7,
        9,
        10,
        5,
        8,
        4,
        2
      ); //将前17位加权因子保存在数组里
      var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
      var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
      for (var i = 0; i < 17; i++) {
        idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
      }

      var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
      var idCardLast = idCard.substring(17); //得到最后一位身份证号码

      //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
      if (idCardMod == 2) {
        if (idCardLast == "X" || idCardLast == "x") {
          return true;
        } else {
          return false;
        }
      } else {
        //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
        if (idCardLast == idCardY[idCardMod]) {
          return true;
        } else {
          return false;
        }
      }
    }
  } else {
    return false;
  }
},
async sleep(timeout:any){
  return new Promise((resolve:any)=>{
    setTimeout(()=>{
      resolve();
    }, timeout)
  })
}
};
