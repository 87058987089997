import Vue from 'vue';
import Vuex from 'vuex';
import routes from '@/router/router'
import router from '@/router';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    stateStatus:0,
    get routerTitle(){
      let title="";
      if(routes.length){
        (routes[0] as any).children.some((s:any)=>{
             if(s.name==router.currentRoute.name ){
              title=s.title;
               return true;
             }
             return false;
        })
      }
      return title;
    },
     // 诚信信息状态 (√)
     reviewAuditType: [
      {
        value: 0,
        label: "全部",
        color: "",
      },
      {
        value: 1,
        label: "已生效",
        color: "#7cb342",
      },
      {
        value: 2,
        label: "待修改",
        color: "#f00",
      },
      {
        value: 3,
        label: "待复核",
        color: "#ffa500",
      },
      {
        value: 4,
        label: "待审核",
        color: "#21BAE9",
      },
      {
        value: 5,
        label: "生效记录待修改",
        color: "#e74c3c",
      },
      {
        value: 6,
        label: "生效记录待复核",
        color: "#ff968a",
      },
      {
        value: 7,
        label: "生效记录待审核",
        color: "#9CE6E9",
      },
    ],
    rainingAgency: [
      {
        label: "全部",
        value: "",
      },
      {
        label: "本机构",
        value: "G",
      },
      {
        label: "其他第三方",
        value: "L",
      },
    ],
  },
  mutations: {
    setDictionaries(state, dictionaries:any) {
      for (let item of dictionaries) {
      const key:string=item.key[0].toLowerCase() + item.key.slice(1);
      let val:any=item.values;
      Vue.set(state,key,val);
    }
    state.stateStatus +=1;
    },
  },
  actions: {
  },
  modules: {
  },
});
