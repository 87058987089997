import http from './http';
function post(url: any) {
	return function (data: any, showLoading = false, progressCallback = null, token = '', loadingMsg = '数据正在加载中,请耐心等待......', config: any) {
		return http.post(url, data, showLoading, loadingMsg, config);
	}
}
export default {
	domain: () => {
		return http.domain()
	},
	GetSignature: 'base/upload/GetSignature',
	'dictionaryApi': '',
	'imageurl_prefix': '',
	adminuser: {
		Detail: post('base/adminuser/Detail'), //用户详情信息 
		List: post('base/adminuser/List'), //用户信息列表 
		Add: post('base/adminuser/Add'), //添加用户信息 
		Delete: post('base/adminuser/Delete'), //删除用户信息 
		Lock: post('base/adminuser/Lock'), //锁定用户 
		DisEnable: post('base/adminuser/DisEnable'), //禁用用户 
		Enable: post('base/adminuser/Enable'), //启用用户 
		Update: post('base/adminuser/Update'), //更新用户信息 
		ChangePassWord: post('base/adminuser/ChangePassWord'), //更改用户密码 
	},




	home: {
		PlatformInfo: post('api/home/PlatformInfo'), //平台信息 
		UserLoginInfo: post('api/home/UserLoginInfo'), //用户以及登录信息 
		TrainInfo: post('api/home/TrainInfo'), //培训信息 
		Test: post('api/home/Test'), // 
		VerificationCode: post('base/home/VerificationCode'), //验证码 
		Login: post('base/home/Login'), //登录 
		Logout: post('base/home/Logout'), //登出 
		ChangePassword: post('base/home/ChangePassword'), //修改密码 
		ChangeCurrentPassword: post('base/home/ChangeCurrentPassword'), //修改当前密码 
		Index: post('base/home/Index'), //首页信息 
		DictionaryList: post('base/home/DictionaryList'), //系统字典 
		PesonalClear: post('api/home/PesonalClear'), //个人信息批量删除 
		TrainingClear: post('api/home/TrainingClear'), //培训档案批量删除 
		SpecifyClear: post('api/home/SpecifyClear'), //批量删除指定信息项 
		MigrateCompany: post('api/home/MigrateCompany'), //用户转移
		GetAllCompany: post('api/home/GetAllCompany'), //获取所有机构,
		GetTestDetail: post('api/home/GetTestDetail'), //获取考试列表,
		UpdateTest: post('api/home/UpdateTest'), //修改考试次数,
		GetCompanyList: post("/manageapi/company/GetCompanyList"), // 获取公司列表
		UpdateCompany: post("/manageapi/company/UpdateCompany"), // 公司修改
		
	},
	member: {
		List: post('api/member/List'), //获取培训档案 
		GetMemberTriningOffline: post('api/member/GetMemberTriningOffline'), //获取培训档案 
		GetMemberTriningDetails: post('api/member/GetMemberTriningDetails'), //档案详情 
		GetArchives: post('api/member/GetArchives'), //个人信息详情 
		GetYear: post('api/member/GetYear'), //培训年份
		Infos: post('api/member/Infos'),//人员档案详情
		BulkDelete: post("api/member/BulkDelete"),//批量删除用户资质 
		Update: post('api/member/Update'),
		DeleteMember: post('api/member/DeleteMember'),
		GetMemberId: post('api/member/GetMemberId'),
		ChangeUserId: post('api/member/ChangeUserId'),
		GetCertificateMerge: post('api/member/GetCertificateMerge'), //合并预览查询
		CertificateMergeSave: post('/api/member/CertificateMergeSave'), //合并预览保存

	},
	memberqualification: {
		BulkDelete: post("manageapi/memberqualification/BulkDelete"),//批量删除用户资质 
		Update: post("manageapi/memberqualification/Update"),//更新用户资质 
	},
	memberbusinessindicators: {
		BulkDelete: post("manageapi/memberbusinessindicators/BulkDelete"),//批量删除业务指标-离职导入
		Update: post("manageapi/memberbusinessindicators/Update"),//更新业务指标 
	},
	membercompanyachievement: {
		BulkDelete: post("manageapi/membercompanyachievement/BulkDelete"),//批量删除业务指标-在职导入
		Update: post("manageapi/membercompanyachievement/Update"),//更新业务指标 
	},
	membercompany: {
		BulkDelete: post("manageapi/membercompany/BulkDelete"),//批量删除人员公司 
		Update: post("manageapi/membercompany/Update"),//更新人员公司 
	},
	memberpracticecertrecord: {
		BulkDelete: post("manageapi/memberpracticecertrecord/BulkDelete"),//批量删除执业证 
		Update: post("manageapi/memberpracticecertrecord/Update"),//更新执业证 
	},
	memberqualificationrecord: {
		BulkDelete: post("manageapi/memberqualificationrecord/BulkDelete"),//批量删除用户资质 
		Update: post("manageapi/memberqualificationrecord/Update"),//更新用户资质 
	},
	memberrecognizance: {
		BulkDelete: post("manageapi/memberrecognizance/BulkDelete"),//批量删除用户资质 
		Update: post("manageapi/memberrecognizance/Update"),//更新用户资质 
	},

	memberrelative: {
		BulkDelete: post("manageapi/memberrelative/BulkDelete"),//批量删除亲属 
		Update: post("manageapi/memberrelative/Update"),//更新亲属 
	},
	membertraining: {
		BulkDelete: post("manageapi/membertraining/BulkDelete"),//批量删除培训信息 
		Update: post("manageapi/membertraining/Update"),//更新培训信息 
	},
	termination: {
		BulkDelete: post("manageapi/termination/BulkDelete"),//被解约 
		Update: post("manageapi/termination/Update"),//批量删除被解约 
	},
	creditchangelog: {
		BulkDelete: post("manageapi/creditchangelog/BulkDelete"),//批量删除诚信分数变更 
		Update: post("manageapi/creditchangelog/Update"),//更新诚信分数变更 
	},
	creditclasslog: {
		BulkDelete: post("manageapi/creditclasslog/BulkDelete"),//批量删除诚信级别记录 
		Update: post("manageapi/creditclasslog/Update"),//更新用户资质 
	},
	creditlog: {
		BulkDelete: post("manageapi/creditlog/BulkDelete"),//批量删除诚信记录 
		Update: post("manageapi/creditlog/Update"),//更新诚信记录 
	},
	creditv2log: {
		BulkDelete: post("manageapi/creditv2log/BulkDelete"),//批量删除新版诚信记录 
		Update: post("manageapi/creditv2log/Update"),//更新新版诚信记录 
	},
	summarymembercompany: {
		BulkDelete: post("manageapi/summarymembercompany/BulkDelete"),//批量删除中间表 
		Update: post("manageapi/summarymembercompany/Update"),//更新中间表 
	},
	coursereport: {
		Querylist: post("manageapi/coursereport/querylist"), // 查询所有课程
		Delete: post("manageapi/coursereport/delete"), // 删除课程
		GetFillingYear: post("manageapi/coursereport/GetFillingYear"), // 查询年份
	},
	deleteNotice: {
		Getlist: post("manageapi/notice/NoticeQueryList"), // 查询消息
		Delete: post("manageapi/notice/NoticeDelete"), // 删除课程
	},
	insidemanage: {
		UpdateInside: post("/manageapi/insidemanage/UpdateInside"), // 更新内勤人员信息
	},
	 temporarySignature: {
		TemporaryList:post("manageapi/memberrecognizance/TemporaryList"), //获取临时用户签名列表
		BulkDeleteTemporaryRecognizance:post("manageapi/memberrecognizance/BulkDeleteTemporaryRecognizance") //删除用户临时签名
	 }
};

