export default [
    {
        path: "/",
        name: "Frame",
        component: () => import("../views/Frame/Frame.vue"),
        redirect: "/PersonalDetails",
        children: [
          {
            path: "/Home",
            name: "Home",
            title:"首页",
            iconName:"zion-home",
            noShow:true,
            component: () => import("../views/Home.vue"),
          },
          {
            path: "/PersonnelInfoInquiry/InfoInquiry",
            name: "InfoInquiry",
            title:"个人信息查询",
            iconName:"zion-circle",
            component: () =>
              import(
                `../views/PersonnelInfoInquiry/InfoInquiry.vue`
              ),
              meta: {
                keepAlive: true,
              },
          },
          {
            path: "/PersonnelInfoInquiry/companyInfoEdit",
            name: "companyInfoEdit",
            title:"公司信息修改",
            iconName:"zion-circle",
            component: () =>
              import(
                `../views/companyInfoEdit/companyInfoEdit.vue`
              ),
              meta: {
                keepAlive: true,
              },
          },
          // {
          //   path: "/TrainingFileManage",
          //   name: "TrainingFileManage",
          //   title:"培训档案管理",
          //   iconName:"zion-file",
          //   component: () => import("../views/TrainingFileManage/TrainingFileManage.vue"),
          //   meta: {
          //     keepAlive: true,
          //   },
          // },
          {
            path: "/TrainingFileManage/TrainingFileDetails",
            name: "TrainingFileDetails",
            title:"培训档案详情",
            noShow:true,
            component: () =>
              import(
                `../views/TrainingFileManage/TrainingFileDetails.vue`
              )
          },
          
          {
            path: "/PersonalFile/PersonalFile",
            name: "PersonalFile",
            title:"个人档案",
            iconName:"",
            noShow:true,
            component: () =>
              import(
                `../views//PersonalFile/PersonalFile.vue`
              )
          },
          {
            path: "/PersonalDetails",
            name: "PersonalDetails",
            title:"人员档案管理",
            iconName:"zion-file",
            component: () => import("../views/PersonalFile/PersonalDetails.vue"),
            meta: {
              keepAlive: true,
            },
          }, 
          {
            path: "/MoveMember",
            name: "MoveMember",
            title:"机构用户转移",
            iconName:"zion-file",
            component: () => import("../views/MoveMember/MoveMember.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/AddTestTime",
            name: "AddTestTime",
            title:"增加考试次数",
            iconName:"zion-file",
            component: () => import("../views/AddTestTime/index.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/ChangeUserId",
            name: "ChangeUserId",
            title:"修改用户Id",
            iconName:"zion-file",
            component: () => import("../views/ChangeUserId/index.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/DeleteTraining",
            name: "DeleteTraining",
            title:"删除课程报备",
            iconName:"zion-file",
            component: () => import("../views/DeleteTraining/DeleteTraining.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/DeleteNotice",
            name: "DeleteNotice",
            title:"删除通知消息",
            iconName:"zion-file",
            component: () => import("../views/DeleteNotice/index.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/EditInfo",
            name: "EditInfo",
            title:"内勤人员信息修改",
            iconName:"zion-file",
            component: () => import("../views/EditInfo/EditInfo.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/IdNumberMerge",
            name: "IdNumberMerge",
            title:"证件号码合并",
            iconName:"zion-file",
            component: () => import("../views/IdNumberMerge/IdNumberMerge.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/DeleteTemporarySignature",
            name: "DeleteTemporarySignature",
            title:"删除用户临时签名",
            iconName:"zion-file",
            component: () => import("../views/DeleteTemporarySignature/index.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/IdNumberMerge/MergePreview",
            name: "MergePreview",
            title:"合并预览",
            iconName:"zion-file",
            noShow:true,
            component: () => import("../views/IdNumberMerge/MergePreview.vue"),
          },
        ]
      },
      {
        path: "/Login",
        name: "Login",
        component: () => import("../views/Login/Login.vue"),
      },
]