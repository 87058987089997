import Vue, { DirectiveOptions } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false;
import '@/assets/css/comment.css'
import "./static/reset.css"; //reset.css
import '@/static/css/project.scss'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});
/* common-sources */
import './static/js/icon_symbol.js'

/* public-method */
import zzlApi from "@/providers/api"
import Format from "@/utils/Format";
Vue.prototype.zzlApi = zzlApi
Vue.prototype.zzlFormat = Format;
Vue.prototype.systemInfo = {
   area:process.env.VUE_APP_AREA,
   env:process.env.VUE_APP_ENV
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
